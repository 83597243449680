import { useOutlets } from 'application/domain/useCase/outlet/outlet'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import { getProducts } from 'application/domain/useCase/products/products'
import Loader from 'application/presentation/common/Loader/Loader'
import { Product } from 'application/domain/entity/product/Product'
import cn from 'classnames'
import { useNavigate } from 'react-router'
import _find from 'lodash/find'

import s from './ProductsAvailableInOutletsPage.module.scss'

const checkSomethingNotInStock = (
  product: Product,
): {
  something_not_in_stock?: boolean
  product_not_in_stock?: boolean
  allCorrect?: boolean
  not_available?: boolean
} => {
  if (product) {
    let somethingNotInStock = false
    if (product.availability_status !== 'in_stock') {
      return { product_not_in_stock: true }
    }
    for (let j = 0; j < product.varieties.length; j++) {
      if (product.varieties[j].availability_status !== 'in_stock') {
        somethingNotInStock = true
        break
      }
    }
    return somethingNotInStock ? { something_not_in_stock: true } : { allCorrect: true }
  }
  return {
    not_available: true,
  }
}

const ProductsAvailableInOutletsPage = () => {
  const navigate = useNavigate()
  const { data, isLoading } = useOutlets()
  const [productsIsLoading, setProductsIsLoading] = useState<boolean>(false)
  const [productsWithOutlets, setProductsWithOutlets] = useState<any>([])
  const outlets = useMemo(() => {
    if (data && data.outlets) {
      return _filter(data.outlets, (item) => item.outlet_id !== 34 && item.outlet_id !== 40)
    }
    return []
  }, [data])

  const onItemClick = useCallback(
    (item_id: number, outlet_id: number) => navigate(`/control/menu/${outlet_id}/${item_id}`),
    [navigate],
  )

  useEffect(() => {
    if (outlets.length) {
      setProductsIsLoading(true)
      Promise.allSettled(_map(outlets, (outlet) => getProducts(outlet.id))).then(
        (result: Array<any>) => {
          setProductsWithOutlets(
            _map(result[0].value.products, (item, index: number) => {
              return {
                id: item.id,
                product_name: item.product_name,
                outlets: _map(outlets, (outlet, outletIndex: number) => ({
                  ...outlet,
                  checkResults: checkSomethingNotInStock(
                    _find(result[outletIndex].value.products, { id: item.id }),
                  ),
                })),
              }
            }),
          )

          setProductsIsLoading(false)
        },
      )
    }
  }, [outlets])

  return isLoading || productsIsLoading ? (
    <Loader />
  ) : productsWithOutlets.length ? (
    <div className={s.table_container}>
      <div className={s.head}>
        <div className={s.column}></div>
        {outlets.map((item) => (
          <div className={s.column} key={`item_outlet_${item.id}`}>
            {item.name} {item.is_distance_order ? '(Д)' : ''}
          </div>
        ))}
      </div>
      {productsWithOutlets.map((item: any) => {
        return (
          <div className={s.row} key={`row_${item.id}`}>
            <div className={s.column}>{item.product_name}</div>
            {item.outlets.map((outlet: any) => (
              <div
                key={`row_item_${item.id}_${outlet.id}`}
                className={cn(s.column, {
                  [s.green]: outlet.checkResults.allCorrect,
                  [s.red]: outlet.checkResults.product_not_in_stock,
                  [s.gray]: outlet.checkResults.not_available,
                  [s.yellow]: outlet.checkResults.something_not_in_stock,
                })}
                onClick={() => onItemClick(item.id, outlet.id)}
              >
                {outlet.checkResults.allCorrect && 'ок'}
                {outlet.checkResults.product_not_in_stock && 'не ок'}
                {outlet.checkResults.something_not_in_stock && 'не ок'}
                {outlet.checkResults.not_available && '_'}
              </div>
            ))}
          </div>
        )
      })}
    </div>
  ) : (
    <div className={s.empty}>Не удалось получить данные</div>
  )
}

export default React.memo(ProductsAvailableInOutletsPage)
