import React from 'react'
import NavSvg from 'images/icons/nav_stat.svg'
import DocSvg from 'images/icons/doc_icon.svg'
import StockSvg from 'images/icons/stock_icon.svg'
import PersonalSvg from 'images/icons/personal_icon.svg'
import ClientsSvg from 'images/icons/clients_icon.svg'
import LockSvg from 'images/icons/lock_icon.svg'

import NavigationItems from '../NavigationItems'

export const adminRoutes = [
  {
    id: 1,
    name: 'Статистика',
    t_name: 'statistics',
    icon: NavSvg,
    items: [
      { name: 'Обработанные заказы', link: '/orders_statistic', t_name: 'orders_statistic' },
      // { name: 'Продажи сегодня', link: '/result/all_stats', t_name: 'result_all_stats' },
      // { name: 'Статистика заказов', link: '/control/clients', t_name: 'control_clients' },
      // { name: 'Финансовая модель', link: '/finance_model', t_name: 'finance_model' },
      // {
      //   name: 'Проданные продукты',
      //   link: '/sold_products',
      //   t_name: 'sold_products',
      // },
      // {
      //   name: 'Статистика в динамике',
      //   link: '/results_dynamic',
      //   t_name: 'results_dynamic',
      // },
      {
        name: 'Продажи сиропов',
        link: '/sold_syrop',
        t_name: 'sold_syrop',
      },
      {
        name: 'Продажи по часам',
        link: '/sold_hours',
        t_name: 'sold_hours',
      },
      {
        name: 'Сравнение по выручке',
        link: '/compare_revenue',
        t_name: 'compare_revenue',
      },
      {
        name: 'Время привоза',
        link: '/delivery_time',
        t_name: 'delivery_time',
      },
      {
        name: 'Активные сессии',
        link: '/active_sessions',
        t_name: 'active_sessions',
      },
      {
        name: 'Сегодняшние заказы',
        link: '/today_orders',
        t_name: 'today_orders',
      },
      {
        name: 'Продажи продуктов на точках по часам',
        link: '/sold_products_by_hour',
        t_name: 'sold_products_by_hour',
      },
      {
        name: 'Динамика себестоимости продуктов по месяцам',
        link: '/products_selfcost_dynamic',
        t_name: 'products_selfcost_dynamic',
      },
      // {
      //   name: 'Проверка меню',
      //   link: '/check_menu',
      //   t_name: 'check_menu',
      // },
    ],
  },
  {
    id: 2,
    name: 'Менеджер меню',
    t_name: 'manager_menu',
    icon: DocSvg,
    items: [
      { name: 'Отображение у клиентов', link: '/control/menu', t_name: 'control_menu' },
      { name: 'Опции и объемы кофе', link: '/product_options', t_name: 'product_options' },
      {
        name: 'Все категории продуктов',
        link: 'all_categ_products',
        t_name: 'all_categ_products',
      },
      // {
      //   name: 'Все продукты',
      //   link: 'all_products',
      //   t_name: 'all_products',
      // },
      {
        name: 'Отображение у клиента',
        link: 'client_display',
        t_name: 'client_display',
      },
    ],
  },
  {
    id: 3,
    name: 'Управление складом',
    t_name: 'control_stock',
    icon: StockSvg,
    items: [
      { name: 'Расходники', link: '/atoms', t_name: 'atoms' },
      { name: 'Склады', link: '/stocks', t_name: 'stocks' },
      {
        name: 'Проверка ревизии',
        link: '/stocks_revision',
        t_name: 'stocks_revision',
      },
      {
        name: 'План заявок',
        link: '/plan',
        t_name: 'plan',
      },
      {
        name: 'Приходы на склад',
        link: '/stock_arrivals',
        t_name: 'stock_arrivals',
      },
      {
        name: 'Списания с центрального склада',
        link: '/stock_writeoff',
        t_name: 'stock_writeoff',
      },
      {
        name: 'Оборудование на точках',
        link: '/stock_equipment',
        t_name: 'stock_equipment',
      },
      {
        name: 'Расходники на точках',
        link: '/stock_consumables',
        t_name: 'stock_consumables',
      },
      {
        name: 'История остатков расходников на точках',
        link: '/stock_consumables_history',
        t_name: 'stock_consumables_history',
      },
      {
        name: 'Планы ревизии',
        link: '/revision_plans',
        t_name: 'revision_plans',
      },
      {
        name: 'Статистика складов',
        link: '/stock_stats',
        t_name: 'stock_stats',
      },
    ],
  },
  {
    id: 4,
    name: 'Менеджер персонала',
    t_name: 'manager_personal',
    icon: PersonalSvg,
    items: [
      {
        name: 'Весь персонал',
        link: '/staff',
        t_name: 'staff',
      },
      // {
      //   name: 'Показатели бариста',
      //   link: '/user_statistics',
      //   t_name: 'user_statistics',
      // },
      // {
      //   name: 'Рейтинг персонала',
      //   link: '/rating',
      //   t_name: 'rating',
      // },
      {
        name: 'Зарплата персонала по точкам',
        link: '/salary_outlets',
        t_name: 'salary_outlets',
      },
      {
        name: 'Зарплата персонала по бариста',
        link: '/salary_barista',
        t_name: 'salary_barista',
      },
      {
        name: 'Зарплата другого персонала',
        link: '/salary_other',
        t_name: 'salary_other',
      },
      // {
      //   name: 'Добавить новую проверку',
      //   link: '/new_check',
      //   t_name: 'new_check',
      // },
      // {
      //   name: 'Уровни сотрудников',
      //   link: '/roles_grades',
      //   t_name: 'roles_grades',
      // },
      {
        name: 'Сводка зп сотрудников',
        link: '/total_salary_admin',
        t_name: 'total_salary_admin',
      },
    ],
  },
  {
    id: 5,
    name: 'Торговые точки и регионы',
    t_name: 'outlets_regions',
    icon: LockSvg,
    items: [
      { name: 'Торговые точки', link: '/outlets', t_name: 'outlets' },
      // {
      //   name: 'Регионы',
      //   link: '/regions',
      //   t_name: 'regions',
      // },
      // {
      //   name: 'Залы',
      //   link: '/areas',
      //   t_name: 'areas',
      // },
      {
        name: 'Столы',
        link: '/tables',
        t_name: 'tables',
      },
    ],
  },
  {
    id: 6,
    name: 'Клиенты',
    t_name: 'clients',
    icon: ClientsSvg,
    items: [
      { name: 'Отзывы и чаевые', link: '/control/reviews', t_name: 'control_reviews' },
      {
        name: 'Отправка уведомлений',
        link: '/app_push',
        t_name: 'app_push',
      },
      { name: 'История клиентов', link: '/history_clients', t_name: 'history_clients' },
      // { name: 'Подарочные карты', link: '/gift_cards', t_name: 'gift_cards' },
    ],
  },
  {
    id: 7,
    name: 'Настройки точки',
    t_name: 'settings_outlet',
    icon: NavSvg,
    items: [
      { name: 'Общие', link: '/settings/outlet', t_name: 'settings_outlet' },
      // { name: 'Чеки', link: '/settings/cheque', t_name: 'settings_cheque' },
      // { name: 'Другое', link: '/other', t_name: 'other' },
      // { name: 'Маркетинг', link: '/marketing', t_name: 'marketing' },
      // { name: 'Брендирование', link: '/app_branding', t_name: 'app_branding' },
      { name: 'Для разработчиков', link: '/settings/dev', t_name: 'settings_dev' },
      {
        name: 'Управление скидками',
        link: '/discount/list',
        t_name: 'discount_list',
      },
    ],
  },
  // {
  //   id: 8,
  //   name: 'Не распределенное',
  //   t_name: 'not_distributed',
  //   icon: NavSvg,
  //   items: [
  //     {
  //       name: 'Партнеры',
  //       link: '/partners',
  //       t_name: 'partners',
  //     },
  //   ],
  // },
]

const AdminMenu = () => {
  return <NavigationItems data={adminRoutes} />
}

export default React.memo(AdminMenu)
