import {
  addAdminOutletRequest,
  deleteAdminOutletRequst,
  editAdminOutletRequest,
  editOutletParameterRequest,
  editOutletProductsRequest,
  fetchAdminOutlet,
  fetchAdminOutlets,
  fetchOutletProducts,
} from 'application/data/api/outlet'
import {
  checkTechTimeRequest,
  editOutletRequset,
  editTechTimeRequest,
  fetchOutlets,
} from 'application/data/api/outlet'
import { AdminOutlet, Outlet } from 'application/domain/entity/outlet/Outlet'
import { CallbackType } from 'application/domain/types/callback'
import { EditAdminOutletData } from 'application/domain/types/requestTypes'
import {
  AdminOutletsResponse,
  CheckOutletResponse,
  EditAdminOutletResponse,
  OutletProductsResponse,
} from 'application/domain/types/responseTypes'
import { useQuery, UseQueryOptions } from 'react-query'

export const getOutlets = fetchOutlets

export const useOutlets = (
  queryConfig?: Omit<UseQueryOptions<{ outlets: Outlet[] }>, 'queryKey' | 'queryFn'>,
) => {
  const query = useQuery<{ outlets: Outlet[] }>('outlets', fetchOutlets, {
    retry: false,
    refetchOnWindowFocus: false,
    ...queryConfig,
  })
  return { ...query, data: query.data ? query.data : { outlets: [] } }
}

export const editOutlet = async ({
  onSuccess,
  onError,
  name,
  address,
  timeWork,
  width,
  height,
  typeName,
}: {
  name: string
  address: string
  timeWork: string
  width: string
  height: string
  typeName: string
} & CallbackType) => {
  try {
    const data = await editOutletRequset(name, address, timeWork, width, height, typeName)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const checkTechTimeOutlet = async ({
  onSuccess,
  onError,
  outlet_id,
}: {
  outlet_id: number
} & CallbackType) => {
  try {
    const data = await checkTechTimeRequest(outlet_id)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const useCheckOutlet = (outlet_id: number) => {
  const query = useQuery<CheckOutletResponse>(
    ['check_outlet', outlet_id],
    () => checkTechTimeRequest(outlet_id),
    {
      refetchInterval: 15000,
    },
  )
  return query
}

export const editTechTimeOutlet = async ({
  onSuccess,
  onError,
  outlet_id,
  outletIsWorking,
  timeStart,
  timeEnd,
}: {
  outlet_id: number
  outletIsWorking: boolean
  timeStart: string
  timeEnd: string
} & CallbackType) => {
  try {
    const data = await editTechTimeRequest(outlet_id, outletIsWorking, timeStart, timeEnd)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const useAdminOutlets = () => {
  const query = useQuery<AdminOutletsResponse>(['adminOutlets'], fetchAdminOutlets)
  return { ...query, data: query.data ? query.data.outletIndex.outlets : [] }
}

export const useAdminOutlet = (id: number) => {
  const query = useQuery<EditAdminOutletResponse>(['adminOutlets', id], () => fetchAdminOutlet(id))
  return { ...query, data: query.data ? query.data.edit : undefined }
}

export const addAdminOutlet = async (sendData: EditAdminOutletData) =>
  addAdminOutletRequest(sendData)

export const editAdminOutlet = async (sendData: EditAdminOutletData) =>
  editAdminOutletRequest(sendData)

export const deleteAdminOutlet = async (id: number) => deleteAdminOutletRequst(id)

export const useOutletProducts = (id: number) => {
  const query = useQuery<OutletProductsResponse>(['outletProducts', id], () =>
    fetchOutletProducts(id),
  )
  return { ...query, data: query.data ? query.data.editProducts : undefined }
}

export const editOutletProducts = async (id: number, products_exist: any) =>
  editOutletProductsRequest(id, products_exist)

export const editOutletParams = editOutletParameterRequest
