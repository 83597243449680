import { ToastContentTemplate } from 'application/presentation/common/ToastNotifications/ToastNotifications'
import { toast } from 'react-toastify'

const ToastNotify = (message: string) => {
  toast(message, {
    position: 'top-center',
    draggable: false,
    autoClose: 2000,
    closeOnClick: false,
    hideProgressBar: true,
    closeButton: false,
    containerId: 'toast',
  })

  return true
}

type NotificationProps = {
  title: string
  message?: string
  info?: string
  type?: 'info' | 'success' | 'warning' | 'error' | 'default'
  error?: any
}

export const showNotification = ({
  title,
  message,
  info,
  type = 'default',
  error,
}: NotificationProps) => {
  return toast(<ToastContentTemplate {...{ title, message, info, type, error }} />, {
    position: 'top-right',
    draggable: false,
    autoClose: 10000,
    closeOnClick: false,
    containerId: 'notification',
    type,
  })
}

export default ToastNotify
