import React, { useMemo } from 'react'
import { Portal } from 'react-portal'
import { ToastContainer, Zoom } from 'react-toastify'

import s from './ToastNotifications.module.scss'

type ToastContainerTemplateProps = {
  title: string
  message: string | undefined
  info: string | undefined
  type: 'info' | 'success' | 'warning' | 'error' | 'default' | undefined
  error: any | undefined
}

export const ToastContentTemplate = React.memo(
  ({ title, message, info, type, error }: ToastContainerTemplateProps) => {
    const parsedInfo = useMemo(() => {
      let msg = message
      let inf = info
      if (type === 'error' && error) {
        if (error.response) {
          msg = `Endpoint: ${error.response.config.url}`
        }
        if (error.response && error.response.data && error.response.data.exception) {
          inf = `${error.response.data.file} \n line:${error.response.data.line} \n message:${error.response.data.message}`
        }
      }
      return {
        message: msg,
        info: inf ? inf.split('\n').map((str) => <p>{str}</p>) : undefined,
      }
    }, [error, type, message, info])

    return (
      <div className={s.notification_container}>
        <div className={s.title}>{title}</div>
        {parsedInfo.message ? <div className={s.message}>{parsedInfo.message}</div> : <></>}
        {parsedInfo.info ? <div className={s.info}>{parsedInfo.info}</div> : <></>}
      </div>
    )
  },
)

const ToastNotifications = () => {
  return (
    <Portal>
      <ToastContainer
        transition={Zoom}
        limit={1}
        autoClose={100}
        enableMultiContainer
        containerId={'toast'}
        className={s.container}
        toastClassName={s.toast}
        bodyClassName={s.toast_body}
      />
      <ToastContainer enableMultiContainer containerId={'notification'} />
    </Portal>
  )
}

export default React.memo(ToastNotifications)
