/* eslint-disable react/require-default-props */
import _ from 'lodash'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import Select from 'react-select'
import './SelectorStyle.scss'
import { ReactComponent as TriggerSvg } from 'images/icons/black_down_trigger.svg'
import { ReactComponent as UploadSvg } from 'images/icons/upload_icon.svg'
import { ReactComponent as PdfSvg } from 'images/icons/pdf.svg'
import { ReactComponent as PngSvg } from 'images/icons/png.svg'
import { ReactComponent as JpgSvg } from 'images/icons/jpg.svg'
import { ReactComponent as FileSvg } from 'images/icons/file_icon.svg'
import { ReactComponent as CloseSvg } from 'images/icons/close.svg'
import { useDropzone } from 'react-dropzone'
import cn from 'classnames'
import ReactTextareaAutosize from 'react-textarea-autosize'
import Switch from 'react-ios-switch'
import InputMask from 'react-input-mask'
import { rusToLatin } from 'application/utils/rusToLatin'

import { Checkbox, ErrorMsg } from '../../uiComponents'

import s from './UniversalInputComponent.module.scss'

export type UniversalInputComponentType =
  | 'text'
  | 'number'
  | 'selector'
  | 'file'
  | 'multi_file'
  | 'password'
  | 'datetime'
  | 'time'
  | 'date'
  | 'textarea'
  | 'multi_checkbox'
  | 'number_dot'
  | 'switch'
  | 'phone'
  | 'masked_text'

type Props = {
  title?: string
  type: UniversalInputComponentType
  onChange: Function
  value: string | number | boolean
  errorMsg?: string
  placeholder?: string
  options?: Array<{ value: number; label: string }>
  containerClassName?: string
  inputContainerClassName?: string
  onKeyUp?: Function
  inputRef?: any
  isClearable?: boolean
  disabled?: boolean
  titleClassName?: string
  inputClassName?: string
  theme?: 'default' | 'gray_with_title'
  onFocus?: any
  onBlur?: any
  onClick?: any
  rightInnerContent?: React.ReactNode
  filterOption?: Function
  mask?: string
  maskChar?: string
  errorBorder?: boolean
  beforeMaskedValueChange?: (
    newState: {
      value: string
      selection: { start: number | null; end: number | null }
    },
    oldState: {
      value: string
      selection: { start: number | null; end: number | null }
    },
    userInput: string,
    maskOptions: any,
  ) => { value: string; selection: { start: number | null; end: number | null } }
}

type CheckboxProps = {
  onChange: Function
  value: any
  multi: boolean
  options: Array<{ label: string; value: number }>
}

const CheckboxComponent = React.memo(({ onChange, value, multi, options }: CheckboxProps) => {
  const onChangeHandler = useCallback(
    (val: number) => {
      const index = _.findIndex(value, (item) => item === val)
      if (index !== -1) {
        return onChange(_.filter(value, (item, i: number) => i !== index))
      }
      if (value == null) return onChange([val])
      return onChange([...value, val])
    },
    [onChange, value],
  )
  return (
    <div>
      {options.map((option, index: number) => {
        const checked = _.findIndex(value, (item) => item === option.value) !== -1
        return (
          <Checkbox
            key={`checkbox_${option.label}_${option.value}`}
            value={checked}
            title={option.label}
            onChange={() => onChangeHandler(option.value)}
          />
        )
      })}
    </div>
  )
})

const DropZone = React.memo(
  ({ onChange, value, multi }: { onChange: Function; value: any; multi: boolean }) => {
    const onDrop = useCallback(
      (acceptedFiles) => {
        const accepted = _.map(acceptedFiles, (item) => {
          item.file = item
          return {
            file: item,
            preview: URL.createObjectURL(item),
          }
        })
        if (multi) {
          if (_.isArray(value) && value.length) {
            onChange(_.concat(value, accepted))
          } else {
            onChange(accepted)
          }
        } else {
          onChange(accepted[0])
        }
      },
      [onChange, multi, value],
    )
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      maxFiles: multi ? 99 : 1,
      // accept: 'image/jpeg, image/png, application/pdf',
    })

    const removeFile = useCallback(
      (e: any, index?: number) => {
        e.stopPropagation()
        if (multi) {
          onChange(_.filter(value, (item: any, i: number) => i !== index))
        } else {
          onChange(null)
        }
      },
      [value, multi, onChange],
    )

    return (
      <div {...getRootProps()}>
        {isDragActive ? (
          <div className={s.upload_btn}>
            <span>Бросьте файл сюда...</span>
          </div>
        ) : (multi && value && value.length) || (!multi && value) ? (
          multi ? (
            <>
              <div className={s.files}>
                {value.map((item: any, i: number) => {
                  return (
                    <div className={s.preview} key={`preview_${i}_${item.preview}`}>
                      {item.file.type.indexOf('image') !== -1 ? (
                        <img src={item.preview} alt="" />
                      ) : (
                        <>
                          <FileSvg />
                          <div className={s.txt}>{item.file.name}</div>
                        </>
                      )}
                      <div className={s.remove_btn} onClick={(e: any) => removeFile(e, i)}>
                        <CloseSvg />
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className={s.upload_btn}>
                <UploadSvg /> <span>Загрузить</span>
              </div>
            </>
          ) : (
            <>
              <div className={s.files}>
                <div className={s.preview}>
                  {value.file.type.indexOf('image') !== -1 ? (
                    <img src={value.preview} alt="" />
                  ) : (
                    <>
                      <FileSvg />
                      <div className={s.txt}>{value.file.name}</div>
                    </>
                  )}
                  <div className={s.remove_btn} onClick={removeFile}>
                    <CloseSvg />
                  </div>
                </div>
              </div>
              <div className={s.upload_btn}>
                <UploadSvg /> <span>Загрузить</span>
              </div>
            </>
          )
        ) : (
          <div className={s.upload_btn}>
            <UploadSvg /> <span>Загрузить</span>
          </div>
        )}
        {}
        <input {...getInputProps()} />
      </div>
    )
  },
)

export const InputComponent = React.memo(
  ({
    type,
    onChange,
    value,
    placeholder,
    options,
    onKeyUp,
    inputRef,
    isClearable,
    disabled,
    inputClassName,
    theme = 'default',
    onFocus,
    onBlur,
    filterOption,
    mask,
    maskChar,
    errorBorder,
    beforeMaskedValueChange,
  }: Props) => {
    const stringValue = typeof value !== 'boolean' ? value : ''
    switch (type) {
      case 'file':
      case 'multi_file':
        return (
          <DropZone
            onChange={onChange}
            value={value}
            multi={type === 'multi_file' ? true : false}
          />
        )
      case 'masked_text':
        return (
          <InputMask
            mask={mask}
            // alwaysShowMask
            // inputRef={inputRef}
            ref={inputRef}
            beforeMaskedValueChange={beforeMaskedValueChange}
            maskChar={maskChar || '_'}
            value={value}
            onChange={(e: any) => onChange(e.target.value)}
            readOnly={disabled || false}
            className={s.input}
            placeholder={placeholder}
            onKeyUp={(e: any) => (onKeyUp ? onKeyUp(e) : {})}
            onFocus={onFocus}
            onBlur={onBlur}
            formatChars={{
              '9': '[0-9]',
              r: '[А-Яа-я]',
              a: '[A-Za-zА-Яа-я]',
              '*': '[A-Za-zА-Яа-я0-9]',
            }}
          />
        )
      case 'phone':
        return (
          <InputMask
            mask="+7 (999) 999-9999"
            alwaysShowMask
            // inputRef={inputRef}
            ref={inputRef}
            maskChar={'_'}
            inputMode="numeric"
            value={value}
            onChange={(e: any) => onChange(e.target.value)}
            readOnly={disabled || false}
            className={`${s.input} ${errorBorder ? s.error : ''}`}
            placeholder={placeholder}
            onKeyUp={(e: any) => (onKeyUp ? onKeyUp(e) : {})}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )
      case 'selector':
        const selectedValue = _.find(options, { value: value })
        return (
          <Select
            key={`my_unique_select_key__${selectedValue}`}
            //@ts-ignore
            options={options}
            value={selectedValue || ''}
            classNamePrefix={
              theme === 'gray_with_title' ? 'grayUniversalCustomSelect' : 'universalCustomSelect'
            }
            placeholder={placeholder || 'Выберите...'}
            isClearable={isClearable || false}
            isSearchable
            filterOption={(option, inputValue) => {
              const val = option.label.toLocaleLowerCase()
              const inputVal = inputValue.toLocaleLowerCase()
              if (filterOption) {
                return (
                  (val.indexOf(inputVal) !== -1 || val.indexOf(rusToLatin(inputVal)) !== -1) &&
                  filterOption(option, inputValue)
                )
              }
              return val.indexOf(inputVal) !== -1 || val.indexOf(rusToLatin(inputVal)) !== -1
            }}
            isDisabled={disabled || false}
            className={`${s.select_container} ${inputClassName || ''}`}
            escapeClearsValue
            backspaceRemovesValue
            //@ts-ignore
            onChange={(val: { label: string; value: number }) => {
              if (val && (typeof val.value === 'number' || typeof val.value === 'string')) {
                onChange(val.value)
              } else {
                onChange(null)
              }
            }}
            components={{
              DropdownIndicator: () => (
                <div className={s.dropdown_trigger}>
                  <TriggerSvg />
                </div>
              ),
              ClearIndicator: ({ innerProps }) => {
                return (
                  <div {...innerProps} className={s.clear_indicator}>
                    <CloseSvg />
                  </div>
                )
              },
            }}
          />
        )
      case 'number':
        return (
          <input
            type="text"
            onChange={(e: any) => onChange(e.target.value.replace(/\D/, ''))}
            value={stringValue}
            readOnly={disabled || false}
            className={`${s.input} ${errorBorder ? s.error : ''}`}
            ref={inputRef}
            placeholder={placeholder}
            onKeyUp={(e: any) => (onKeyUp ? onKeyUp(e) : {})}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )
      case 'number_dot':
        return (
          <input
            type="text"
            onChange={(e: any) => {
              const reg = /^[0-9]*\.?[0-9]{0,4}$/
              if (reg.test(e.target.value)) {
                onChange(e.target.value.replace(/[^\d\.]/g, ''))
              }
            }}
            value={stringValue}
            readOnly={disabled || false}
            className={`${s.input} ${errorBorder ? s.error : ''}`}
            ref={inputRef}
            placeholder={placeholder}
            onKeyUp={(e: any) => (onKeyUp ? onKeyUp(e) : {})}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )
      case 'datetime':
      case 'date':
      case 'time':
        return (
          <input
            type={type === 'datetime' ? 'datetime-local' : type}
            onChange={(e: any) => onChange(e.target.value)}
            value={stringValue}
            className={s.input}
            readOnly={disabled || false}
            placeholder={placeholder}
            ref={inputRef}
          />
        )
      case 'textarea':
        return (
          <ReactTextareaAutosize
            onChange={(e: any) => onChange(e.target.value)}
            value={stringValue}
            className={s.input}
            readOnly={disabled || false}
            placeholder={placeholder}
            ref={inputRef}
            onKeyUp={(e: any) => (onKeyUp ? onKeyUp(e) : {})}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )
      case 'password':
      case 'text':
        return (
          <input
            type={type}
            onChange={(e: any) => onChange(e.target.value)}
            value={stringValue}
            className={`${s.input} ${errorBorder ? s.error : ''}`}
            readOnly={disabled || false}
            placeholder={placeholder}
            ref={inputRef}
            onKeyUp={(e: any) => (onKeyUp ? onKeyUp(e) : {})}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )

      case 'multi_checkbox':
        return (
          <CheckboxComponent
            onChange={onChange}
            value={value}
            multi={type === 'multi_checkbox' ? true : false}
            options={options || []}
          />
        )

      case 'switch':
        return (
          <Switch
            checked={value}
            className={`${s.switch} ${inputClassName || ''} ${value ? s.checked : ''}`}
            onChange={onChange}
          />
        )
      default:
        return <></>
    }
  },
)

const ContainerComponent = React.memo(
  ({
    type,
    containerClassName,
    needAnimTitle,
    focused,
    onClickHandler,
    children,
  }: {
    focused: boolean
    needAnimTitle: boolean
    children: React.ReactNode
    type: string
    containerClassName?: string
    onClickHandler: any
  }) => {
    switch (type) {
      case 'switch':
      case 'file':
      case 'multi_file':
        return (
          <div
            onClick={onClickHandler}
            className={cn(s.container, containerClassName || '', {
              [s.with_title]: needAnimTitle,
              [s.focus]: needAnimTitle && focused,
            })}
          >
            {children}
          </div>
        )
      default:
        return (
          <label
            onClick={onClickHandler}
            className={cn(s.container, containerClassName || '', {
              [s.with_title]: needAnimTitle,
              [s.focus]: needAnimTitle && focused,
            })}
          >
            {children}
          </label>
        )
    }
  },
)

const UniversalInputComponent = ({
  title,
  type,
  onChange,
  value,
  placeholder,
  options,
  errorMsg,
  containerClassName,
  inputContainerClassName,
  onKeyUp,
  inputRef,
  isClearable,
  disabled,
  titleClassName,
  inputClassName,
  theme = 'default',
  onBlur,
  onClick,
  mask,
  maskChar,
  rightInnerContent,
  errorBorder,
  beforeMaskedValueChange,
}: Props) => {
  const [focused, setFocused] = useState<boolean>(
    type === 'phone' || type === 'selector' ? true : false,
  )

  const needAnimTitle = useMemo(() => theme === 'gray_with_title', [theme])

  const onFocus = useCallback(() => {
    setFocused(true)
  }, [])
  const onBlurHandler = useCallback(() => {
    if (
      type !== 'phone' &&
      type !== 'selector' &&
      (!value || (typeof value === 'string' && value.length === 0))
    ) {
      setFocused(false)
    }
    if (onBlur) {
      onBlur()
    }
  }, [value, type, onBlur])

  const onClickHandler = useCallback(() => {
    if (onClick) {
      onClick()
    }
  }, [onClick])

  useEffect(() => {
    if (value || (typeof value === 'string' && value.length)) {
      onFocus()
    }
  }, [value, onFocus])

  return (
    <ContainerComponent {...{ type, containerClassName, needAnimTitle, focused, onClickHandler }}>
      {title ? (
        <div
          className={`${s.title} ${needAnimTitle ? s.little_title : ''} ${titleClassName || ''}`}
        >
          {title}
        </div>
      ) : (
        ''
      )}

      <div className={cn(s.input_container, inputContainerClassName || '')}>
        <InputComponent
          type={type || 'text'}
          {...{
            title,
            onChange,
            value,
            placeholder,
            options,
            onKeyUp,
            inputRef,
            isClearable,
            disabled,
            inputClassName,
            onFocus,
            onBlur: onBlurHandler,
            theme,
            mask,
            maskChar,
            errorBorder,
            beforeMaskedValueChange,
          }}
        />
        {rightInnerContent ? <div className={s.right_content}>{rightInnerContent}</div> : <></>}
        {errorMsg ? <ErrorMsg text={errorMsg} /> : <></>}
      </div>
    </ContainerComponent>
  )
}

export default React.memo(UniversalInputComponent)
