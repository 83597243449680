import {
  fetchClientConfigSearchRequest,
  fetchClientConfigsByClientId,
} from 'application/data/api/client_configs'
import { useQuery } from 'react-query'
import _filter from 'lodash/filter'
import _reverse from 'lodash/reverse'
import { useEffect, useState } from 'react'

export const getClientConfigs = async (client_id: number, type?: 'car' | 'coffe') => {
  const data = await fetchClientConfigsByClientId(client_id)
  return data
    ? type
      ? type === 'car'
        ? _filter(data, (item) => (item.car ? true : false))
        : _filter(data, (item) => (item.product ? true : false))
      : data
    : undefined
}

export const useClientConfigs = (client_id: number, type?: 'car' | 'coffe') => {
  const [data, setData] = useState<any>()
  const query = useQuery(
    ['client_configs', client_id],
    () => fetchClientConfigsByClientId(client_id),
    { enabled: client_id ? true : false },
  )
  useEffect(() => {
    setData(
      query.data
        ? type
          ? type === 'car'
            ? _reverse(_filter(query.data, (item) => (item.car ? true : false)))
            : _reverse(_filter(query.data, (item) => (item.product ? true : false)))
          : query.data
        : undefined,
    )
  }, [query.data, type])
  return { ...query, isLoading: client_id ? query.isLoading : false, data: data }
}

export const searchClientConfigs = fetchClientConfigSearchRequest
