import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { getDefaultOutlet, isBarista, userIsActive } from 'application/presentation/UserController'
import { setOutlet } from 'application/domain/store/reducers/userSlice'
import { useOutlets } from 'application/domain/useCase/outlet/outlet'
import { useTranslation } from 'react-i18next'
import NavSvg from 'images/icons/nav_stat.svg'
import DocSvg from 'images/icons/doc_icon.svg'
import StockSvg from 'images/icons/stock_icon.svg'
import ClientsSvg from 'images/icons/clients_icon.svg'
import { useNavigate } from 'react-router'
import moment from 'moment'
import { useAppSelector } from 'application/domain/store/hooks'

import Loader from '../../Loader'
import s from '../NavigationMenu.module.scss'
import NavigationItems from '../NavigationItems'

type RoutesType = Array<{
  id: number
  name: string
  defaultOpened?: boolean
  t_name: string
  icon: string
  items: Array<{ name: string; link: string; t_name: string; notForBooking?: boolean }>
}>
const baristaRoutes: RoutesType = [
  {
    id: 1,
    name: 'Управление сменой',
    defaultOpened: true,
    t_name: 'control_managment',
    icon: NavSvg,
    items: [
      {
        name: '{session_action} смены',
        link: '/kassa_work',
        t_name: '',
      },
    ],
  },
  {
    id: 2,
    name: 'Менеджер меню',
    t_name: 'manager_menu',
    icon: DocSvg,
    items: [
      {
        name: 'Отображение у клиентов',
        link: '/control/menu/{outlet_id}',
        t_name: 'control_menu',
      },
    ],
  },
  {
    id: 3,
    name: 'Управление складом',
    t_name: 'control_stock',
    icon: StockSvg,
    items: [
      {
        name: 'Управление остатками',
        link: '/control/stock',
        t_name: 'control_stock',
      },
    ],
  },
  {
    id: 4,
    name: 'Клиенты',
    t_name: 'clients',
    icon: ClientsSvg,
    items: [
      {
        name: 'Новые клиенты',
        link: '/clients_table',
        t_name: 'clients_table',
      },
      {
        name: 'Отзывы и чаевые',
        link: '/control/reviews/{outlet_id}',
        t_name: 'control_reviews',
        notForBooking: true,
      },
    ],
  },
  {
    id: 5,
    name: 'Финансы',
    t_name: 'finance',
    icon: NavSvg,
    items: [
      {
        name: 'Инкассация',
        link: '/incassation',
        t_name: 'incassation',
        notForBooking: true,
      },
      {
        name: 'Выдача зп сотруднику',
        link: '/salary_employee',
        t_name: 'salary_employee',
      },
      {
        name: 'Моя текущая зп',
        link: '/my_current_salary',
        t_name: 'my_current_salary',
      },
    ],
  },
  {
    id: 6,
    name: 'Статистика',
    t_name: 'statistics',
    icon: NavSvg,
    items: [
      {
        name: 'Продажи сегодня',
        link: '/',
        t_name: 'result_all_stats',
      },
      {
        name: 'Мой рейтинг',
        link: '/rating',
        t_name: 'rating',
      },
    ],
  },
  {
    id: 7,
    name: 'Настройки точки',
    t_name: 'settings_outlet',
    icon: NavSvg,
    items: [
      {
        name: 'Общие',
        link: '/settings/outlet',
        t_name: 'settings_outlet',
      },
      {
        name: 'Чеки',
        link: '/settings/cheque',
        t_name: 'settings_cheque',
      },
      {
        name: 'Для разработчиков',
        link: '/settings/dev',
        t_name: 'settings_dev',
      },
    ],
  },
]

const BaristaMenu = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const outlet = useAppSelector(({ userReducer }) => userReducer.outlet)
  const user = useAppSelector(({ userReducer }) => userReducer.user)
  const collapseNavMenu = useAppSelector(({ navReducer }) => navReducer.collapseNavMenu)
  const appMode = useAppSelector(({ userReducer }) => userReducer.appMode)

  const stateActive: boolean = useMemo(() => userIsActive(user), [user])
  const { data, isLoading } = useOutlets()

  useEffect(() => {
    if (!outlet && data) {
      const outletId = getDefaultOutlet(user)
      setOutlet(outletId === 0 ? null : _.find(data.outlets, { id: outletId }) || null)(dispatch)
    }
    if (data && data.outlets.length && isBarista(user)) {
      const outletId = getDefaultOutlet(user)
      setOutlet(outletId === 0 ? null : _.find(data.outlets, { id: outletId }) || null)(dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, user])

  const routesData = useMemo(() => {
    const routes = _.map(baristaRoutes, (item) => ({
      ...item,
      items: item.items
        ? _.map(item.items, (item2) => ({
            ...item2,
            name: item2.name.replace('{session_action}', stateActive ? 'Закрытие' : 'Открытие'),
            link: item2.link.replace('{outlet_id}', outlet ? `${outlet.id}` : ''),
            t_name:
              item2.name.indexOf('{session_action}') !== -1
                ? stateActive
                  ? 'kassa_work_off'
                  : 'kassa_work_on'
                : item2.t_name,
          }))
        : undefined,
    }))
    if (appMode === 'booking') {
      return _.map(routes, (item) => ({
        ...item,
        items: _.filter(item.items, (item2) => (!item2.notForBooking ? true : false)),
      }))
    }
    return routes
  }, [outlet, stateActive, appMode])

  const onSelectorClickHandler = useCallback(() => navigate('/kassa_work'), [navigate])

  return (
    <>
      <div className={`${s.selector_content} ${collapseNavMenu ? s.hide_content : ''}`}>
        <div
          className={`${s.selector} ${collapseNavMenu ? s.hide : ''}`}
          onClick={onSelectorClickHandler}
        >
          <span>
            {stateActive
              ? outlet
                ? outlet.name
                : t('nav.menu.control_managment.non_outlet')
              : t('nav.menu.control_managment.on_kassa')}
            {stateActive && (
              <>
                <br />
                <span className={s.opened_from}>
                  открыто с {moment(user?.session.start).format('HH:mm')}
                </span>
              </>
            )}
          </span>
          {isLoading ? (
            <div className={s.loader}>
              <Loader color="#000" size={16} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <NavigationItems data={routesData} />

      {/* <DropDown
        data={outlets}
        disabled={true}
        triggerComponent={() => (
          <div className={s.selector}>
            <span>{outlet ? outlet.name : `Все бары (${outlets ? outlets.length - 1 : 0})`}</span>
            <span>
              <TriggerSvg />
            </span>
            {isLoading ? (
              <div className={s.loader}>
                <Loader color="#000" size={16} />
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      /> */}
    </>
  )
}

export default React.memo(BaristaMenu)
